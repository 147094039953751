import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import EditUserLayer from "../../components/admins/EditUserLayer";


const EditUserPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Edit User" />

        {/* AddUserLayer */}
        <EditUserLayer />


      </MasterLayout>
    </>
  );
};

export default EditUserPage;
