import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AllCustomersLayer from "../../components/customers/AllCustomersLayer";


const AllCustomersPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="All Customers" />

        {/* TableDataLayer */}
        <AllCustomersLayer />
        {/* <AlertLayer/> */}
    

      </MasterLayout>

    </>
  );
};

export default AllCustomersPage; 
