import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

import AllAdminsLayer from "../../components/admins/AllAdminsLayer";
import Breadcrumb from "../../components/Breadcrumb";


const AllAdminsPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="All Users" />

        {/* TableDataLayer */}
        <AllAdminsLayer />
        {/* <AlertLayer/> */}
    

      </MasterLayout>

    </>
  );
};

export default AllAdminsPage; 
