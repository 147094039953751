import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomePageOne from "./pages/HomePageOne";
import HomePageTwo from "./pages/HomePageTwo";
import HomePageThree from "./pages/HomePageThree";
import HomePageFour from "./pages/HomePageFour";
import HomePageFive from "./pages/HomePageFive";
import HomePageSix from "./pages/HomePageSix";
import HomePageSeven from "./pages/HomePageSeven";
import EmailPage from "./pages/EmailPage";
import AddUserPage from "./pages/admins/AddUserPage";
import AlertPage from "./pages/AlertPage";
import AssignRolePage from "./pages/AssignRolePage";
import AvatarPage from "./pages/AvatarPage";
import BadgesPage from "./pages/BadgesPage";
import ButtonPage from "./pages/ButtonPage";
import CalendarMainPage from "./pages/CalendarMainPage";
import CardPage from "./pages/CardPage";
import CarouselPage from "./pages/CarouselPage";
import ChatEmptyPage from "./pages/ChatEmptyPage";
import ChatMessagePage from "./pages/ChatMessagePage";
import ChatProfilePage from "./pages/ChatProfilePage";
import CodeGeneratorNewPage from "./pages/CodeGeneratorNewPage";
import CodeGeneratorPage from "./pages/CodeGeneratorPage";
import ColorsPage from "./pages/ColorsPage";
import ColumnChartPage from "./pages/ColumnChartPage";
import CompanyPage from "./pages/CompanyPage";
import CurrenciesPage from "./pages/CurrenciesPage";
import DropdownPage from "./pages/DropdownPage";
import ErrorPage from "./pages/ErrorPage";
import FaqPage from "./pages/FaqPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import FormLayoutPage from "./pages/FormLayoutPage";
import FormValidationPage from "./pages/FormValidationPage";
import FormPage from "./pages/FormPage";
import GalleryPage from "./pages/GalleryPage";
import ImageGeneratorPage from "./pages/ImageGeneratorPage";
import ImageUploadPage from "./pages/ImageUploadPage";
import InvoiceAddPage from "./pages/InvoiceAddPage";
import InvoiceEditPage from "./pages/InvoiceEditPage";
import InvoiceListPage from "./pages/InvoiceListPage";
import InvoicePreviewPage from "./pages/InvoicePreviewPage";
import KanbanPage from "./pages/KanbanPage";
import LanguagePage from "./pages/LanguagePage";
import LineChartPage from "./pages/LineChartPage";
import ListPage from "./pages/ListPage";
import MarketplaceDetailsPage from "./pages/MarketplaceDetailsPage";
import MarketplacePage from "./pages/MarketplacePage";
import NotificationAlertPage from "./pages/NotificationAlertPage";
import NotificationPage from "./pages/NotificationPage";
import PaginationPage from "./pages/PaginationPage";
import PaymentGatewayPage from "./pages/PaymentGatewayPage";
import PieChartPage from "./pages/PieChartPage";
import PortfolioPage from "./pages/PortfolioPage";
import PricingPage from "./pages/PricingPage";
import ProgressPage from "./pages/ProgressPage";
import RadioPage from "./pages/RadioPage";
import RoleAccessPage from "./pages/RoleAccessPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import StarRatingPage from "./pages/StarRatingPage";
import StarredPage from "./pages/StarredPage";
import SwitchPage from "./pages/SwitchPage";
import TableBasicPage from "./pages/TableBasicPage";
import TableDataPage from "./pages/TableDataPage";
import TabsPage from "./pages/TabsPage";
import TagsPage from "./pages/TagsPage";
import TermsConditionPage from "./pages/TermsConditionPage";
import TextGeneratorPage from "./pages/TextGeneratorPage";
import ThemePage from "./pages/ThemePage";
import TooltipPage from "./pages/TooltipPage";
import TypographyPage from "./pages/TypographyPage";
import UsersGridPage from "./pages/UsersGridPage";
import UsersListPage from "./pages/UsersListPage";
import ViewDetailsPage from "./pages/ViewDetailsPage";
import VideoGeneratorPage from "./pages/VideoGeneratorPage";
import VideosPage from "./pages/VideosPage";
import ViewProfilePage from "./pages/ViewProfilePage";
import VoiceGeneratorPage from "./pages/VoiceGeneratorPage";
import WalletPage from "./pages/WalletPage";
import WidgetsPage from "./pages/WidgetsPage";
import WizardPage from "./pages/WizardPage";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import TextGeneratorNewPage from "./pages/TextGeneratorNewPage";

import AllAdminsPage from "./pages/admins/AllAdminsPage";
import { useState } from "react";
import { createContext } from "react";
import EditUserPage from "./pages/admins/EditUserPage";
import AllCustomersPage from "./pages/customers/AllCustomersPage";
import AllBanksPage from "./pages/banks/AllBanksPage";
import AddBankPage from "./pages/banks/AddBankPage";
import EditBankPage from "./pages/banks/EditBankPage";
import AllCardsPage from "./pages/banks/AllCardsPage";
import AddCardPage from "./pages/banks/AddCardPage";
import EditCardPage from "./pages/banks/EditCardPage";
import AllBannersPage from "./pages/media/AllBannersPage";
import AddBannerPage from "./pages/media/AddBannerPage";
import EditPhasePage from "./pages/location/EditPhasePage";
import AddPhasePage from "./pages/location/AddPhasePage";
import AllPhasesPage from "./pages/location/AllPhasesPage";
import EditAreaPage from "./pages/location/EditAreaPage";
import AddAreaPage from "./pages/location/AddAreaPage";
import AllAreasPage from "./pages/location/AllAreasPage";
import AllRecipesPage from "./pages/recipes/AllRecipesPage";
import AllRecipeCategoryPage from "./pages/recipeCategory/AllRecipeCategoryPage";
import AddRecipeCategoryPage from "./pages/recipeCategory/AddRecipeCategoryPage";
import EditRecipeCategoryPage from "./pages/recipeCategory/EditRecipeCategoryPage";
import AllRestaurantCategoryPage from "./pages/restaurantCategory/AllRestaurantCategoryPage";
import AddRestaurantCategoryPage from "./pages/restaurantCategory/AddRestaurantCategoryPage";
import EditRestaurantCategoryPage from "./pages/restaurantCategory/EditRestaurantCategoryPage";
import AllDishCategoryPage from "./pages/dishCategory/AllDishCategoryPage";
import AddDishCategoryPage from "./pages/dishCategory/AddDishCategoryPage";
import EditDishCategoryPage from "./pages/dishCategory/EditDishCategoryPage";
import AllDishCuisinePage from "./pages/dishCuisine/AllDishCuisinePage";
import AddDishCuisinePage from "./pages/dishCuisine/AddDishCuisinePage";
import EditDishCuisinePage from "./pages/dishCuisine/EditDishCuisinePage";
import AllRecipeReviewPage from "./pages/recipeReview/AllRecipeReviewPage";
import AllRestaurantReviewPage from "./pages/restaurantReview/AllRestaurantReviewPage";
const ModeContext = createContext();
const ProfileContext = createContext();
const UserPageContext = createContext();
const CustomerPageContext = createContext();
const BankPageContext = createContext();
const CardPageContext = createContext();
const BannerPageContext = createContext();
const AreaPageContext = createContext();
const PhasePageContext = createContext();
const PendingRecipePageContext = createContext();
const ApprovedRecipePageContext = createContext();
const RejectedRecipePageContext = createContext();
const RecipeCategoryPageContext = createContext();
const RestaurantCategoryPageContext = createContext();
const DishCategoryPageContext = createContext();
const DishCuisinePageContext = createContext();
const PendingRecipeReviewPageContext = createContext();
const ApprovedRecipeReviewPageContext = createContext();
const RejectedRecipeReviewPageContext = createContext();
const PendingRestaurantReviewPageContext = createContext();
const ApprovedRestaurantReviewPageContext = createContext();
const RejectedRestaurantReviewPageContext = createContext();

function App() {
  const [mode, setMode] = useState("light");
  const [profileUpdate, setProfileUpdate] = useState(0);
  const [userPage, setUserPage] = useState(1);
  const [customerPage, setCustomerPage] = useState(1);
  const [bankPage, setBankPage] = useState(1);
  const [cardPage, setCardPage] = useState(1);
  const [bannerPage, setBannerPage] = useState(1);
  const [areaPage, setAreaPage] = useState(1);
  const [phasePage, setPhasePage] = useState(1);
  const [pendingRecipePage, setPendingRecipePage] = useState(1);
  const [approvedRecipePage, setApprovedRecipePage] = useState(1);
  const [rejectedRecipePage, setRejectedRecipePage] = useState(1);
  const [pendingRecipeReviewPage, setPendingRecipeReviewPage] = useState(1);
  const [approvedRecipeReviewPage, setApprovedRecipeReviewPage] = useState(1);
  const [rejectedRecipeReviewPage, setRejectedRecipeReviewPage] = useState(1);
  const [pendingRestaurantReviewPage, setPendingRestaurantReviewPage] = useState(1);
  const [approvedRestaurantReviewPage, setApprovedRestaurantReviewPage] = useState(1);
  const [rejectedRestaurantReviewPage, setRejectedRestaurantReviewPage] = useState(1);
  const [recipeCategoryPage, setRecipeCategoryPage] = useState(1);
  const [restaurantCategoryPage, setRestaurantCategoryPage] = useState(1);
  const [dishCategoryPage, setDishCategoryPage] = useState(1);
  const [dishCuisinePage, setDishCuisinePage] = useState(1);

  return (
    <ModeContext.Provider value={{ mode, setMode }}>
      <ProfileContext.Provider value={{ profileUpdate, setProfileUpdate }}>
        <UserPageContext.Provider value={{ userPage, setUserPage }}>
          <CustomerPageContext.Provider
            value={{ customerPage, setCustomerPage }}
          >
            <BankPageContext.Provider value={{ bankPage, setBankPage }}>
              <CardPageContext.Provider value={{ cardPage, setCardPage }}>
                <BannerPageContext.Provider
                  value={{ bannerPage, setBannerPage }}
                >
                  <AreaPageContext.Provider value={{ areaPage, setAreaPage }}>
                    <PhasePageContext.Provider
                      value={{ phasePage, setPhasePage }}
                    >
                      <PendingRecipePageContext.Provider
                        value={{ pendingRecipePage, setPendingRecipePage }}
                      >
                        <ApprovedRecipePageContext.Provider
                          value={{ approvedRecipePage, setApprovedRecipePage }}
                        >
                          <RejectedRecipePageContext.Provider
                            value={{
                              rejectedRecipePage,
                              setRejectedRecipePage,
                            }}
                          >
                            <RecipeCategoryPageContext.Provider
                              value={{
                                recipeCategoryPage,
                                setRecipeCategoryPage,
                              }}
                            >
                              <RestaurantCategoryPageContext.Provider
                                value={{
                                  restaurantCategoryPage,
                                  setRestaurantCategoryPage,
                                }}
                              >
                                <DishCategoryPageContext.Provider
                                  value={{
                                    dishCategoryPage,
                                    setDishCategoryPage,
                                  }}
                                >
                                  <DishCuisinePageContext.Provider
                                    value={{
                                      dishCuisinePage,
                                      setDishCuisinePage,
                                    }}
                                  >
                                    <PendingRecipeReviewPageContext.Provider
                                      value={{
                                        pendingRecipeReviewPage,
                                        setPendingRecipeReviewPage,
                                      }}
                                    >
                                      <ApprovedRecipeReviewPageContext.Provider
                                        value={{
                                          approvedRecipeReviewPage,
                                          setApprovedRecipeReviewPage,
                                        }}
                                      >
                                        <RejectedRecipeReviewPageContext.Provider
                                          value={{
                                            rejectedRecipeReviewPage,
                                            setRejectedRecipeReviewPage,
                                          }}
                                        >
                                    <PendingRestaurantReviewPageContext.Provider
                                      value={{
                                        pendingRestaurantReviewPage,
                                        setPendingRestaurantReviewPage,
                                      }}
                                    >
                                      <ApprovedRestaurantReviewPageContext.Provider
                                        value={{
                                          approvedRestaurantReviewPage,
                                          setApprovedRestaurantReviewPage,
                                        }}
                                      >
                                        <RejectedRestaurantReviewPageContext.Provider
                                          value={{
                                            rejectedRestaurantReviewPage,
                                            setRejectedRestaurantReviewPage,
                                          }}
                                        >
                                          <BrowserRouter>
                                            <RouteScrollToTop />
                                            <Routes>
                                              <Route
                                                exact
                                                path="/"
                                                element={<HomePageOne />}
                                              />
                                              <Route
                                                exact
                                                path="/index-2"
                                                element={<HomePageTwo />}
                                              />
                                              <Route
                                                exact
                                                path="/index-3"
                                                element={<HomePageThree />}
                                              />
                                              <Route
                                                exact
                                                path="/index-4"
                                                element={<HomePageFour />}
                                              />
                                              <Route
                                                exact
                                                path="/index-5"
                                                element={<HomePageFive />}
                                              />
                                              <Route
                                                exact
                                                path="/index-6"
                                                element={<HomePageSix />}
                                              />
                                              <Route
                                                exact
                                                path="/index-7"
                                                element={<HomePageSeven />}
                                              />

                                              {/* SL */}
                                              {/* <Route exact path="/add-user" element={<AddUserPage />} /> */}
                                              <Route
                                                exact
                                                path="/alert"
                                                element={<AlertPage />}
                                              />
                                              <Route
                                                exact
                                                path="/assign-role"
                                                element={<AssignRolePage />}
                                              />
                                              <Route
                                                exact
                                                path="/avatar"
                                                element={<AvatarPage />}
                                              />
                                              <Route
                                                exact
                                                path="/badges"
                                                element={<BadgesPage />}
                                              />
                                              <Route
                                                exact
                                                path="/button"
                                                element={<ButtonPage />}
                                              />
                                              <Route
                                                exact
                                                path="/calendar-main"
                                                element={<CalendarMainPage />}
                                              />
                                              <Route
                                                exact
                                                path="/calendar"
                                                element={<CalendarMainPage />}
                                              />
                                              <Route
                                                exact
                                                path="/card"
                                                element={<CardPage />}
                                              />
                                              <Route
                                                exact
                                                path="/carousel"
                                                element={<CarouselPage />}
                                              />
                                              <Route
                                                exact
                                                path="/chat-empty"
                                                element={<ChatEmptyPage />}
                                              />
                                              <Route
                                                exact
                                                path="/chat-message"
                                                element={<ChatMessagePage />}
                                              />
                                              <Route
                                                exact
                                                path="/chat-profile"
                                                element={<ChatProfilePage />}
                                              />
                                              <Route
                                                exact
                                                path="/code-generator"
                                                element={<CodeGeneratorPage />}
                                              />
                                              <Route
                                                exact
                                                path="/code-generator-new"
                                                element={
                                                  <CodeGeneratorNewPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/colors"
                                                element={<ColorsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/column-chart"
                                                element={<ColumnChartPage />}
                                              />
                                              <Route
                                                exact
                                                path="/company"
                                                element={<CompanyPage />}
                                              />
                                              <Route
                                                exact
                                                path="/currencies"
                                                element={<CurrenciesPage />}
                                              />
                                              <Route
                                                exact
                                                path="/dropdown"
                                                element={<DropdownPage />}
                                              />
                                              <Route
                                                exact
                                                path="/email"
                                                element={<EmailPage />}
                                              />
                                              <Route
                                                exact
                                                path="/faq"
                                                element={<FaqPage />}
                                              />
                                              <Route
                                                exact
                                                path="/forgot-password"
                                                element={<ForgotPasswordPage />}
                                              />
                                              <Route
                                                exact
                                                path="/form-layout"
                                                element={<FormLayoutPage />}
                                              />
                                              <Route
                                                exact
                                                path="/form-validation"
                                                element={<FormValidationPage />}
                                              />
                                              <Route
                                                exact
                                                path="/form"
                                                element={<FormPage />}
                                              />
                                              <Route
                                                exact
                                                path="/gallery"
                                                element={<GalleryPage />}
                                              />
                                              <Route
                                                exact
                                                path="/image-generator"
                                                element={<ImageGeneratorPage />}
                                              />
                                              <Route
                                                exact
                                                path="/image-upload"
                                                element={<ImageUploadPage />}
                                              />
                                              <Route
                                                exact
                                                path="/invoice-add"
                                                element={<InvoiceAddPage />}
                                              />
                                              <Route
                                                exact
                                                path="/invoice-edit"
                                                element={<InvoiceEditPage />}
                                              />
                                              <Route
                                                exact
                                                path="/invoice-list"
                                                element={<InvoiceListPage />}
                                              />
                                              <Route
                                                exact
                                                path="/invoice-preview"
                                                element={<InvoicePreviewPage />}
                                              />
                                              <Route
                                                exact
                                                path="/kanban"
                                                element={<KanbanPage />}
                                              />
                                              <Route
                                                exact
                                                path="/language"
                                                element={<LanguagePage />}
                                              />
                                              <Route
                                                exact
                                                path="/line-chart"
                                                element={<LineChartPage />}
                                              />
                                              <Route
                                                exact
                                                path="/list"
                                                element={<ListPage />}
                                              />
                                              <Route
                                                exact
                                                path="/marketplace-details"
                                                element={
                                                  <MarketplaceDetailsPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/marketplace"
                                                element={<MarketplacePage />}
                                              />
                                              <Route
                                                exact
                                                path="/notification-alert"
                                                element={
                                                  <NotificationAlertPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/notification"
                                                element={<NotificationPage />}
                                              />
                                              <Route
                                                exact
                                                path="/pagination"
                                                element={<PaginationPage />}
                                              />
                                              <Route
                                                exact
                                                path="/payment-gateway"
                                                element={<PaymentGatewayPage />}
                                              />
                                              <Route
                                                exact
                                                path="/pie-chart"
                                                element={<PieChartPage />}
                                              />
                                              <Route
                                                exact
                                                path="/portfolio"
                                                element={<PortfolioPage />}
                                              />
                                              <Route
                                                exact
                                                path="/pricing"
                                                element={<PricingPage />}
                                              />
                                              <Route
                                                exact
                                                path="/progress"
                                                element={<ProgressPage />}
                                              />
                                              <Route
                                                exact
                                                path="/radio"
                                                element={<RadioPage />}
                                              />
                                              <Route
                                                exact
                                                path="/role-access"
                                                element={<RoleAccessPage />}
                                              />

                                              <Route
                                                exact
                                                path="/sign-up"
                                                element={<SignUpPage />}
                                              />
                                              <Route
                                                exact
                                                path="/star-rating"
                                                element={<StarRatingPage />}
                                              />
                                              <Route
                                                exact
                                                path="/starred"
                                                element={<StarredPage />}
                                              />
                                              <Route
                                                exact
                                                path="/switch"
                                                element={<SwitchPage />}
                                              />
                                              <Route
                                                exact
                                                path="/table-basic"
                                                element={<TableBasicPage />}
                                              />
                                              <Route
                                                exact
                                                path="/table-data"
                                                element={<TableDataPage />}
                                              />
                                              <Route
                                                exact
                                                path="/tabs"
                                                element={<TabsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/tags"
                                                element={<TagsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/terms-condition"
                                                element={<TermsConditionPage />}
                                              />
                                              <Route
                                                exact
                                                path="/text-generator-new"
                                                element={
                                                  <TextGeneratorNewPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/text-generator"
                                                element={<TextGeneratorPage />}
                                              />
                                              <Route
                                                exact
                                                path="/theme"
                                                element={<ThemePage />}
                                              />
                                              <Route
                                                exact
                                                path="/tooltip"
                                                element={<TooltipPage />}
                                              />
                                              <Route
                                                exact
                                                path="/typography"
                                                element={<TypographyPage />}
                                              />
                                              <Route
                                                exact
                                                path="/users-grid"
                                                element={<UsersGridPage />}
                                              />
                                              <Route
                                                exact
                                                path="/users-list"
                                                element={<UsersListPage />}
                                              />
                                              <Route
                                                exact
                                                path="/view-details"
                                                element={<ViewDetailsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/video-generator"
                                                element={<VideoGeneratorPage />}
                                              />
                                              <Route
                                                exact
                                                path="/videos"
                                                element={<VideosPage />}
                                              />
                                              <Route
                                                exact
                                                path="/view-profile"
                                                element={<ViewProfilePage />}
                                              />
                                              <Route
                                                exact
                                                path="/voice-generator"
                                                element={<VoiceGeneratorPage />}
                                              />
                                              <Route
                                                exact
                                                path="/wallet"
                                                element={<WalletPage />}
                                              />
                                              <Route
                                                exact
                                                path="/widgets"
                                                element={<WidgetsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/wizard"
                                                element={<WizardPage />}
                                              />
                                              <Route
                                                exact
                                                path="/wallet"
                                                element={<WalletPage />}
                                              />

                                              {/* new */}
                                              <Route
                                                exact
                                                path="/sign-in"
                                                element={<SignInPage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-users"
                                                element={<AllAdminsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/add-user"
                                                element={<AddUserPage />}
                                              />
                                              <Route
                                                exact
                                                path="/edit-user/:id"
                                                element={<EditUserPage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-customers"
                                                element={<AllCustomersPage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-banks"
                                                element={<AllBanksPage />}
                                              />
                                              <Route
                                                exact
                                                path="/add-bank"
                                                element={<AddBankPage />}
                                              />
                                              <Route
                                                exact
                                                path="/edit-bank/:id"
                                                element={<EditBankPage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-cards/:id"
                                                element={<AllCardsPage />}
                                              />
                                              <Route
                                                exact
                                                path="/add-card/:id"
                                                element={<AddCardPage />}
                                              />
                                              <Route
                                                exact
                                                path="/edit-card/:id/:bankId"
                                                element={<EditCardPage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-banners"
                                                element={<AllBannersPage />}
                                              />
                                              <Route
                                                exact
                                                path="/add-banner"
                                                element={<AddBannerPage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-areas"
                                                element={<AllAreasPage />}
                                              />
                                              <Route
                                                exact
                                                path="/add-area"
                                                element={<AddAreaPage />}
                                              />
                                              <Route
                                                exact
                                                path="/edit-area/:id"
                                                element={<EditAreaPage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-phases/:id"
                                                element={<AllPhasesPage />}
                                              />
                                              <Route
                                                exact
                                                path="/add-phase/:id"
                                                element={<AddPhasePage />}
                                              />
                                              <Route
                                                exact
                                                path="/edit-phase/:id/:areaId"
                                                element={<EditPhasePage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-recipes"
                                                element={<AllRecipesPage />}
                                              />

                                              <Route
                                                exact
                                                path="/all-recipe-category"
                                                element={
                                                  <AllRecipeCategoryPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/add-recipe-category"
                                                element={
                                                  <AddRecipeCategoryPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/edit-recipe-category/:id"
                                                element={
                                                  <EditRecipeCategoryPage />
                                                }
                                              />

                                              <Route
                                                exact
                                                path="/all-restaurant-category"
                                                element={
                                                  <AllRestaurantCategoryPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/add-restaurant-category"
                                                element={
                                                  <AddRestaurantCategoryPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/edit-restaurant-category/:id"
                                                element={
                                                  <EditRestaurantCategoryPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/all-dish-category"
                                                element={
                                                  <AllDishCategoryPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/add-dish-category"
                                                element={
                                                  <AddDishCategoryPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/edit-dish-category/:id"
                                                element={
                                                  <EditDishCategoryPage />
                                                }
                                              />
                                              <Route
                                                exact
                                                path="/all-dish-cuisine"
                                                element={<AllDishCuisinePage />}
                                              />
                                              <Route
                                                exact
                                                path="/add-dish-cuisine"
                                                element={<AddDishCuisinePage />}
                                              />
                                              <Route
                                                exact
                                                path="/edit-dish-cuisine/:id"
                                                element={
                                                  <EditDishCuisinePage />
                                                }
                                              />

                                              <Route
                                                exact
                                                path="/all-recipe-reviews"
                                                element={<AllRecipeReviewPage />}
                                              />
                                              <Route
                                                exact
                                                path="/all-restaurant-reviews"
                                                element={<AllRestaurantReviewPage />}
                                              />

                                              <Route
                                                exact
                                                path="*"
                                                element={<ErrorPage />}
                                              />
                                            </Routes>
                                          </BrowserRouter>
                                        </RejectedRestaurantReviewPageContext.Provider>
                                      </ApprovedRestaurantReviewPageContext.Provider>
                                    </PendingRestaurantReviewPageContext.Provider>
                                        </RejectedRecipeReviewPageContext.Provider>
                                      </ApprovedRecipeReviewPageContext.Provider>
                                    </PendingRecipeReviewPageContext.Provider>
                                  </DishCuisinePageContext.Provider>
                                </DishCategoryPageContext.Provider>
                              </RestaurantCategoryPageContext.Provider>
                            </RecipeCategoryPageContext.Provider>
                          </RejectedRecipePageContext.Provider>
                        </ApprovedRecipePageContext.Provider>
                      </PendingRecipePageContext.Provider>
                    </PhasePageContext.Provider>
                  </AreaPageContext.Provider>
                </BannerPageContext.Provider>
              </CardPageContext.Provider>
            </BankPageContext.Provider>
          </CustomerPageContext.Provider>
        </UserPageContext.Provider>
      </ProfileContext.Provider>
    </ModeContext.Provider>
  );
}

export default App;
export {
  ProfileContext,
  UserPageContext,
  CustomerPageContext,
  BankPageContext,
  CardPageContext,
  BannerPageContext,
  ModeContext,
  AreaPageContext,
  PhasePageContext,
  PendingRecipePageContext,
  ApprovedRecipePageContext,
  RejectedRecipePageContext,
  RecipeCategoryPageContext,
  RestaurantCategoryPageContext,
  DishCategoryPageContext,
  DishCuisinePageContext,
  PendingRecipeReviewPageContext,
  ApprovedRecipeReviewPageContext,
  RejectedRecipeReviewPageContext,
  PendingRestaurantReviewPageContext,
  ApprovedRestaurantReviewPageContext,
  RejectedRestaurantReviewPageContext,
};
